export enum VehicleOwnership {
  Owned = 'owned',
  Leased = 'leased',
  GovernmentTender = 'government-tender',
}

export enum VehicleDriveStatus {
  DriverOn = 'driver-on',
  OnTrip = 'on-trip',
  DriverOff = 'driver-off',
}

export enum VehicleApprovalStatus {
  Pending = 'pending',
  Approved = 'approved',
}

export enum VehicleActivityStatus {
  Active = 'active',
  Inactive = 'inactive',
  Suspended = 'suspended',
}
